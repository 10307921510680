angular.module("informaApp")
    .controller("UserSearchesCtrl",
        function ($scope, SavedSearchesCrossData, NavigatorService, SaveSearchSvc, ModalHelper, ConstantsSvc, $timeout, CompanyModesEnum, FilterMapper, OmnitureSvc) {
            $scope.loading = true;

            $scope.removalSearch = null;
            $scope.dateFormat = ConstantsSvc.Dates.fullAngularFormat;

            $scope.fields = {
                name: {sort: true, propName: "name"},
                date: {sort: null, propName: "createdAt"},
                totalWhenSaved: {sort: null, propName: "totalWhenSaved"},
            };

            $scope.sortField = $scope.fields.name.propName;

            reloadData();

            $scope.openSearch = function (search) {
                SavedSearchesCrossData.data = search;
                NavigatorService.openTable();
            };

            $scope.removeSearch = function (search) {
                $scope.removalSearch = search;

                ModalHelper.showModal("#searchRemovalModal");
            };

            $scope.confirmRemoving = function () {
                SaveSearchSvc.deleteSearch($scope.removalSearch.id).then(function () {
                    reloadData();
                });
            };

            $scope.switchViewMode = function (search, element) {
                search.isFullMode = !search.isFullMode;

                var container = $(element).parent().find(".query-container");
                container.toggleClass("hidden-content");
            };

            $scope.onTableDraw = function () {
                $timeout(function () {
                    $(".user-searches .query-container").each(function (i, x) {
                        if (x.offsetHeight >= x.scrollHeight) {
                            $(x).parent().find(".show-more").hide();
                            $(x).removeClass("hidden-content");
                        }
                    });
                });
            };

            $scope.switchSorting = function (field) {
                var newValue = field.sort == null ? true : !field.sort;

                clearSorting();

                field.sort = newValue;

                sort(field);
            };

            $scope.shareSearch = shareSearch;

            function shareSearch(search, e) {
                OmnitureSvc.shareSearch(search.name);

                var element = $(e.target);

                if (search.sharedLink) {
                    showPopover(element, createLink(search.sharedLink));
                    return;
                }

                SaveSearchSvc.generateSharedLink(search.id).then(function (sharedLink) {
                    search.sharedLink = sharedLink;
                    showPopover(element, createLink(sharedLink));
                });
            }

            function createLink(uuid) {
                return ConstantsSvc.DOMAIN + 'table/' + uuid;
            }

            function showPopover(element, sharedLink) {
                element
                    .popover({
                        title: 'Share the link',
                        html: true,
                        content: getHtmlForShareSearch(sharedLink),
                        placement: 'top',
                        trigger: 'manual',
                        sanitizeFn: content => content
                        // popover use "sanitize" for removing "unsafe" dom elements (like input, button etc),
                        // so it provides sanitizeFn option, if this option is defined it will override the default sanitize function
                    })
                    .popover('toggle');
            }

            function getHtmlForShareSearch(value) {
                var onclick = '$(this).closest(\'.input-group\').find(\'input\').select(); document.execCommand(\'copy\')';

                return '<div class="input-group share-link">' +
                    '<input type="input" readonly value="' + value + '"/>' +
                    '<span class="input-group-btn">' +
                    '<button class="btn btn-admin" onclick="' + onclick + '">' +
                    '<span class="glyphicon glyphicon-copy"></span>' +
                    ' Copy</button>' +
                    '</span>' +
                    '</div>';
            }

            function sort(field) {
                $scope.sortField = (field.sort === false ? "-" : "") + field.propName;
            }

            function clearSorting() {
                var keys = _.keys($scope.fields);

                for (var i = 0; i < keys.length; i++) {
                    $scope.fields[keys[i]].sort = null;
                }
            }

            function reloadData() {
                SaveSearchSvc.allSearchesByUserId()
                    .then(function (res) {
                        $scope.loading = false;
                        $scope.savedSearches = res.data.data.map(mapSavedSearch);
                    });
            }

            function mapSavedSearch(savedSearch) {
                const newValue = {
                    searchView: FilterMapper.mapToReadableView(savedSearch.filter, savedSearch.totalWhenSaved, savedSearch.tableDurationIsMean)
                };

                return _.merge({}, savedSearch, newValue);
            }

            $timeout(function () {
                $('body').on('click', function (e) {
                    $('.user-searches [data-original-title]').each(function () {
                        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                            $(this).popover('hide');
                        }
                    });
                });
            });
        });